(function () {
  'use strict';

  angular
    .module('neo.home.statistics')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.statistics', {
        abstract: true,
        url: '/statistics',
        templateUrl: 'home/statistics/statistics.tpl.html',
        controller: 'StatisticsCtrl',
        controllerAs: 'vm',
        resolve: {
          categories: function (Categories) {
            return Categories.query().$promise;
          },
          genders: function (Genders) {
            return Genders.query().$promise;
          },
          seasons: function (Seasons) {
            return Seasons.query().$promise;
          },
          currentSeason: function (CurrentSeason) {
            return CurrentSeason.get().$promise;
          }
        }
      });
  }
}());
